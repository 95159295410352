import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loader"
}

import { generalStore } from '@/store';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = generalStore();
const router = useRouter();

return (_ctx: any,_cache: any) => {
  const _component_NavigationComponent = _resolveComponent("NavigationComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.meta.showNavbar === true)
      ? (_openBlock(), _createBlock(_component_NavigationComponent, { key: 0 }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _unref(router).currentRoute.value.name
    })),
    (_unref(store).loader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "loader-box" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})