import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/reset-password/:email/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/account-setup/:email/:token',
    name: 'AccountSetup',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AccountSetup.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/',
    name: 'LandingDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/LandingDashboard.vue'),
    meta: {
      showNavbar: true,
      requiresAuth: true,
    },
  },

  {
    path: '/logs',
    name: 'LogsView',
    component: () => import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsView.vue'),
    meta: {
      showNavbar: true,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/logs/:id',
    name: 'LogsDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsDetails.vue'),
    meta: {
      roles: ['system-administrator'],
      showNavbar: false,
    },
  },

  {
    path: '/scopes',
    name: 'ScopesManagement',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ScopesManagement.vue'),
    meta: {
      showNavbar: true,
      roles: ['system-administrator'],
    },
  },

  {
    path: '/privileges-management',
    name: 'PrivilegesManagement',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/PrivilegesManagement.vue'),
    meta: {
      showNavbar: true,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/client-selection',
    name: 'ClientSelection',
    component: () => import(/* webpackChunkName: "user" */ '@/views/ClientSelection.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/clients',
    name: 'ClientsView',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientsView.vue'),
    meta: {
      showNavbar: true,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/clients/:uuid',
    name: 'ClientDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientDetails.vue'),
    meta: {
      showNavbar: false,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users-global',
    name: 'UsersViewGlobal',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/UsersViewGlobal.vue'),
    meta: {
      showNavbar: true,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users-global/:id',
    name: 'UserDetailsGlobal',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/UserDetailsGlobal.vue'),
    meta: {
      showNavbar: false,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users',
    name: 'UsersView',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UsersView.vue'),
    meta: {
      showNavbar: true,
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UserDetails.vue'),
    meta: {
      showNavbar: false,
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/passwords',
    name: 'PasswordsView',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PasswordsView.vue'),
    meta: {
      showNavbar: true,
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/passwords/:id',
    name: 'PasswordDetails',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PasswordDetails.vue'),
    meta: {
      scopes: ['tenant-management'],
      showNavbar: false,
    },
  },
  {
    path: '/links',
    name: 'LinksManagement',
    component: () => import(/* webpackChunkName: "user" */ '@/views/LinksManagement.vue'),
    meta: {
      showNavbar: true,
    },
  },
  {
    path: '/email-accounts',
    name: 'EmailAccountsView',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/EmailAccountsView.vue'),
    meta: {
      showNavbar: true,
      scopes: ['tenant-management'],
      privileges: ['mails', 'system-emails'],
    },
  },
  {
    path: '/email-accounts/:id',
    name: 'EmailAccountDetails',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/EmailAccountDetails.vue'),
    meta: {
      scopes: ['tenant-management'],
      showNavbar: false,
      privileges: ['mails', 'system-emails'],
    },
  },
  {
    path: '/privileges-configurator',
    name: 'PrivilegesConfiguration',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PrivilegesConfiguration.vue'),
    meta: {
      showNavbar: true,
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
    meta: {
      showNavbar: true,
      privileges: ['contact'],
    },
  },
  {
    path: '/contacts/:id',
    name: 'ContactDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['contact'],
    },
  },

  {
    path: '/features',
    name: 'FeaturesView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
    meta: {
      showNavbar: true,
      privileges: ['features'],
    },
  },
  {
    path: '/features/:id',
    name: 'FeatureDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['features'],
    },
  },

  {
    path: '/issues',
    name: 'IssuesView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
    meta: {
      showNavbar: true,
      privileges: ['issues'],
    },
  },
  {
    path: '/issues/:id',
    name: 'IssueDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['issues'],
    },
  },
  {
    path: '/subscribers',
    name: 'SubscribersView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
    meta: {
      showNavbar: true,
      privileges: ['subscribe'],
    },
  },
  {
    path: '/system-emails',
    name: 'SystemEmailsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsView.vue'),
    meta: {
      showNavbar: true,
      privileges: ['system-emails'],
    },
  },
  {
    path: '/system-emails/:id',
    name: 'SystemEmailsDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['system-emails'],
    },
  },
  {
    path: '/system-emails/create',
    name: 'SystemEmailsCreate',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['system-emails'],
    },
  },
  {
    path: '/mails',
    name: 'MailsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailsView.vue'),
    meta: {
      showNavbar: true,
      privileges: ['mails'],
    },
  },
  {
    path: '/mails/:id',
    name: 'MailDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailDetails.vue'),
    meta: {
      privileges: ['mails'],
      showNavbar: false,
    },
  },

  {
    path: '/mails/create',
    name: 'MailBuilder',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailDetails.vue'),
    meta: {
      privileges: ['mails'],
      showNavbar: false,
    },
  },

  {
    path: '/stock',
    name: 'StockView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/StockView.vue'),
    meta: {
      showNavbar: true,
      privileges: ['stock'],
    },
  },
  {
    path: '/stock/:id',
    name: 'StockDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/StockDetails.vue'),
    meta: {
      showNavbar: false,
      privileges: ['stock'],
    },
  },
  {
    path: '/stock/scanner',
    name: 'StockScanner',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/scanner/StockScanner.vue'),
    meta: {
      showNavbar: true,
      privileges: ['stock'],
    },
  },
  {
    path: '/stock/scanner/:id',
    name: 'StockQuantity',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/scanner/StockQuantity.vue'),
    meta: {
      showNavbar: true,
      privileges: ['stock'],
    },
  },
  {
    path: '/stock/scanner/create/:code',
    name: 'StockScannerCreate',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/scanner/StockScannerCreate.vue'),
    meta: {
      showNavbar: true,
      privileges: ['stock'],
    },
  },
  {
    path: '/stock/scanner/:id/success/:action/:quantity',
    name: 'ScannerSuccessPage',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/scanner/ScannerSuccessPage.vue'),
    meta: {
      showNavbar: true,
      privileges: ['stock'],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

export default router;
